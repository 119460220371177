import './src/css/header.scss';
import './src/css/footer.scss';
import './src/css/index.scss';
import './src/css/resource.scss';
import './src/css/resources.scss';
import './src/css/similar-resources.scss';
import './src/css/cart.scss';
import './src/css/responsive.scss';
import './src/css/pages.scss';
import './src/css/creator.scss';
import React from 'react';
import { StoreContextProvider } from './src/context/StoreContext';
import { GatsbyBrowser } from 'gatsby';
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
    element,
}) => <StoreContextProvider>{element}</StoreContextProvider>;

const addScript = (url: string) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
};

const addPopupScript = (url: string) => {
    const script = document.createElement('script');
    script.id = 'mailmunch-script';
    script.setAttribute('data-mailmunch-site-id', '951015');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
};

export const onClientEntry = () => {
    window.onload = () => {
        addScript('//code.tidio.co/pouveadki6iy6nvfflhhpjfa1cunkghl.js');
        addPopupScript('//a.mailmunch.co/app/v1/site.js');
    };
    document.addEventListener('contextmenu', e => {
        // @ts-expect-error
        if (e.target.tagName === 'IMG') {
            e.preventDefault();
            e.stopPropagation();
        }
    });
};
