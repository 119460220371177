import React, {
    useState,
    useEffect,
    useContext,
    Dispatch,
    SetStateAction,
    ReactNode,
} from 'react';
import Client, { Checkout } from 'shopify-buy';
import { message } from 'antd';

const SHOPIFY_CHECKOUT_STORAGE_KEY = 'shopify_checkout_id';

const client = Client.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN!,
    domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
    apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION!,
});

interface StoreState {
    client: Client;
    isAdding: boolean;
    checkout: Checkout;
}

const initialStoreState: StoreState = {
    client,
    isAdding: false,
    // @ts-expect-error
    checkout: { lineItems: [] },
};

const StoreContext = React.createContext<{
    store: StoreState;
    setStore: Dispatch<SetStateAction<StoreState>>;
}>({
    store: initialStoreState,
    setStore: () => null,
});

function createNewCheckout(client: Client) {
    return client.checkout.create();
}

function fetchCheckout(client: Client, id: string) {
    return client.checkout.fetch(id);
}

function setCheckoutInState(
    checkout: Checkout,
    setStore: Dispatch<SetStateAction<StoreState>>
) {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
        localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
    }

    setStore(prevState => {
        return { ...prevState, checkout };
    });
}

interface Props {
    children: ReactNode;
}

const StoreContextProvider = ({ children }: Props) => {
    const [store, setStore] = useState(initialStoreState);

    useEffect(() => {
        const initializeCheckout = async () => {
            // Check for an existing cart.
            const isBrowser = typeof window !== 'undefined';
            const existingCheckoutId = isBrowser
                ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
                : null;

            if (existingCheckoutId) {
                try {
                    const checkout = await fetchCheckout(
                        client,
                        existingCheckoutId
                    );

                    // Make sure this cart hasn’t already been purchased.
                    if (!checkout.completedAt) {
                        setCheckoutInState(checkout, setStore);
                        return;
                    }
                } catch (e) {
                    // @ts-expect-error
                    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
                }
            }

            const newCheckout = await createNewCheckout(client);
            setCheckoutInState(newCheckout, setStore);
        };

        initializeCheckout();
    }, []);

    return (
        <StoreContext.Provider
            value={{
                store,
                setStore,
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

function useStore() {
    const { store } = useContext(StoreContext);
    return store;
}

function useCartCount() {
    const {
        store: { checkout },
    } = useContext(StoreContext);

    const count = checkout.lineItems.reduce(
        (runningTotal, item) => item.quantity + runningTotal,
        0
    );

    return count;
}

function useCartTotals() {
    const {
        store: { checkout },
    } = useContext(StoreContext);

    const tax = checkout.totalTaxV2
        ? `$${Number(checkout.totalTaxV2.amount).toFixed(2)}`
        : '-';
    const total = checkout.totalPriceV2
        ? `$${Number(checkout.totalPriceV2.amount).toFixed(2)}`
        : '-';

    return {
        tax,
        total,
    };
}

function useCartItems() {
    const {
        store: { checkout },
    } = useContext(StoreContext);

    return checkout.lineItems;
}

function useAddItemToCart() {
    const {
        store: { checkout, client },
        setStore,
    } = useContext(StoreContext);

    async function addItemToCart(variantId: string, quantity: string) {
        if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
        } else {
            message.success({
                // duration: 100,
                content: 'Item added to cart',
                className: 'cart-success',
                style: {
                    marginTop: '20vh',
                },
            });
        }

        setStore(prevState => {
            return { ...prevState, isAdding: true };
        });

        const checkoutId = checkout.id;
        const lineItemsToAdd = [
            { variantId, quantity: parseInt(quantity, 10) },
        ];

        const newCheckout = await client.checkout.addLineItems(
            checkoutId,
            lineItemsToAdd
        );

        setStore(prevState => {
            return { ...prevState, checkout: newCheckout, isAdding: false };
        });
    }

    return addItemToCart;
}

function useRemoveItemFromCart() {
    const {
        store: { client, checkout },
        setStore,
    } = useContext(StoreContext);

    async function removeItemFromCart(itemId: string) {
        const newCheckout = await client.checkout.removeLineItems(checkout.id, [
            itemId,
        ]);

        setStore(prevState => {
            return { ...prevState, checkout: newCheckout };
        });
    }

    return removeItemFromCart;
}

function useCheckout() {
    const {
        store: { checkout },
    } = useContext(StoreContext);

    return () => {
        const newUrlFragments = checkout.webUrl.split('/');
        newUrlFragments[2] = '252mya.com';
        const newUrl = newUrlFragments.join('/');
        // console.log(checkout.webUrl.split('/'))
        window.open(newUrl);
    };
}

export {
    StoreContextProvider,
    useAddItemToCart,
    useStore,
    useCartCount,
    useCartItems,
    useCartTotals,
    useRemoveItemFromCart,
    useCheckout,
};
