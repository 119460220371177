exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-commissions-tsx": () => import("./../../../src/pages/commissions.tsx" /* webpackChunkName: "component---src-pages-commissions-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-creators-tsx": () => import("./../../../src/pages/creators.tsx" /* webpackChunkName: "component---src-pages-creators-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-licensing-agreement-tsx": () => import("./../../../src/pages/licensing-agreement.tsx" /* webpackChunkName: "component---src-pages-licensing-agreement-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-creator-template-tsx": () => import("./../../../src/templates/creatorTemplate.tsx" /* webpackChunkName: "component---src-templates-creator-template-tsx" */),
  "component---src-templates-resource-template-tsx": () => import("./../../../src/templates/resourceTemplate.tsx" /* webpackChunkName: "component---src-templates-resource-template-tsx" */)
}

